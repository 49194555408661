import { gql } from "@apollo/client";
import { Order, OrderCreateInput } from "../types";

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($order: OrderCreateInput!) {
    UserOrderCreate(order: $order) {
      _id
    }
  }
`;

export interface createOrderData {
    UserOrderCreate: Order;
}
  
export interface createOrderVariables {
    order: OrderCreateInput
}