import { gql } from '@apollo/client';

export const USER_PRODUCTS = gql`
  query USER_PRODUCTS {
    UserProducts {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      image {
          url
          name
      }
    }
  }
`;


