import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { userLogged } from '../../storage/userState';
import { authLink } from './authLink';
import { onErrorRefreshTokenLink as onError } from './onErrorRefreshTokenLink';
import { createUploadLink } from 'apollo-upload-client'
import { appState } from '../../storage/appState';
import { CONFIG, LOG } from '../../config';

let client: any = null;
let onErrorRefreshTokenLink = onError;

const log = LOG.extend('Apollo')

const createApolloClient = async () => {

  try {
    log.debug(`Creating apollo client: ${CONFIG.backend.url}`)
    const uploadLink = createUploadLink({ uri: CONFIG.backend.url }) as unknown as ApolloLink

    const omitTypename = (key: string, value: string) => {
      return key === '__typename' ? undefined : value
    }

    const typenameMiddleware = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        // if there are files, i will not parse them
        const image = (operation.variables.image)? operation.variables.image : null
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
        if (image) operation.variables.image = image
      }
      return forward(operation)
    });

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            userLogged: {
              read() {
                return userLogged();
              },
            },
            appState: {
              read() {
                return appState()
              }
            }
          },
        },
      },
    });

    client = new ApolloClient({
      cache,
      link: from([authLink, onErrorRefreshTokenLink, typenameMiddleware, uploadLink]),
      resolvers: {},
    });
  } catch ({ message }) {
    log.error(`Catched error in creating the client: ${message}`);
  }
  return client;
};

const getApolloClient = () => {
  return client;
};

export { createApolloClient, getApolloClient };
