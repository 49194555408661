import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import {
  ApolloProvider, useQuery,
} from "@apollo/client";
import { appStorage } from './storage';
import { Admin } from "./layouts/Admin";
import { userLogged } from './storage/userState';
import { Auth } from './layouts/Auth';
import { createApolloClient } from './libs/apollo/createClient';
import { Test } from './views/tests/test';
import {store} from './storage/redux/store'
import { Provider } from 'react-redux'
import { LOG } from './config';
import { InitApp, InitAppRefreshShopFromCloud } from './layouts/InitApp';
import PWAPrompt from 'react-ios-pwa-prompt'
import { USER_LOGGED } from './storage/queries/userState';
import { Login } from './views/auth/Login';
const log = LOG.extend('Index')

const Root = () => {
  const [client, setClient] = useState<any>(undefined);

  const initApp = async () => {
    log.info('Initializing App')
    const auth = await appStorage.getObject('user');
    const apolloClient = await createApolloClient();

    if (auth && auth.user && auth.user._id) {
      log.debug('User already logged')
      userLogged(auth.user._id)
    } else {
      log.debug('User not logged')
    }

    if (apolloClient) {
      setClient(apolloClient);
    } else {
      log.error('Something went wrong in initializing Apollo Client');
    }
  };

  useEffect(() => {
    initApp();
  }, []);

  return (
    <>
      <PWAPrompt 
        timesToShow={500}
        promptOnVisit={1}
        copyTitle="Aggiungi APP" 
        copyBody="Questo sito può essere utilizzato come App. Aggiungilo alla schermata home per vederlo in fullscreen" 
        copyShareButtonLabel="Premi il bottone nella barra qui sotto per condividere"
        copyAddHomeButtonLabel="Premi 'Aggiungi alla schermata home'"
        copyClosePrompt={null}
        />
      { client ? (
      <ApolloProvider client={client}>
        <Provider store={store}>
          {/* <InitApp> */}
            {/* <InitAppRefreshShopFromCloud> */}
              <BrowserRouter basename=''>
                  <Routes>
                    {/* <Route path="test/*" element={<Test />} /> */}
                    {/* Routes autenticazione */}
                    <Route path="auth/*" element={<Auth />} />
                    {/* <Route path="*" element={<Admin />} /> */}
                    {/* <Route path="test/" element={<Test />} /> */}
                    <Route path="*" element={<Admin />} />
                  </Routes>
              </BrowserRouter>
            {/* </InitAppRefreshShopFromCloud> */}
          {/* </InitApp> */}
        </Provider>
      </ApolloProvider>
      ) : <div>loading</div>
      }
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
// swDev()