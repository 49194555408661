import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { LOG } from "../../config"
import { selectShop } from "../../storage/redux/shopSlice"

const log = LOG.extend('unknown')

export const ShoppingCart = () => {
    log.debug('ShoppingCart')
    const shop = useSelector(selectShop);
    const pathName = useLocation().pathname;
  
    const productsinCart = shop.products.filter((product) => (product && product.quantity && product.quantity>0))

    const Home = <Link className='' to='/'>
        <div className="flex flex-col">
            <div>
                <img className="h-14 rounded-full" src='/images/farm.png' alt='Home' />
            </div>
        </div>
    </Link>

    const Shop = <Link className='' to='/shop'>
        <div className="flex flex-col">
            {productsinCart.length > 0 ? <div className="text-sm text-center">
                {productsinCart.length}
            </div> : null
            }
            <div className="">
                {
                    (productsinCart.length > 0) ?  <img className="h-8" src="/images/shoppingCart.png" alt="Carrello" />:
                    <img className="h-14" src="/images/emptyShopCart.png" alt="Carrello" />
                }
            </div>
        </div>
    </Link>

    return (pathName === '/shop')? Home : Shop
}