import { LOG } from "../../config";
import { appStorage, TAuthUser } from "../../storage";
import { logout, refreshToken } from "../auth";
import { onErrorRefreshTokenLinkCreator } from "./onErrorRefreshTokenLinkCreator";

const log = LOG.extend('Unknown')

const onErrorRefreshTokenLink = onErrorRefreshTokenLinkCreator({
  authorizationHeaderKey: "authentication",
  fetchNewAccessToken: async (rToken: string) => {
    const refreshTokensResponse = await refreshToken(rToken);
    return refreshTokensResponse;
  },
  getAccessToken: () => {
    return appStorage.getObject('user').acceessToken
  },
  getRefreshToken: () => {
    return appStorage.getObject('user').refreshToken
  },
  isUnauthenticatedError: (graphQLError, operationName) => {
    const { extensions, message } = graphQLError;
    log.debug("GRAPHQL QUERY ERROR | " + operationName + " | " + message);
    if (message === "jwt expired") {
      log.debug(
        operationName + " | jwt expired, try to refresh accessToken"
      );
      return true;
    }
    return false;
  },
  isUnauthorizedError: (graphQLError, operationName) => {
    const { extensions, message } = graphQLError;
    log.debug("GRAPHQL QUERY ERROR | " + operationName + " | " + message);
    if (message === "Invalid token") {
      log.debug(
        operationName + " | Invalid token, you are not authorized"
      );
      return true;
    }
    return false;
  },
  onSuccessfulRefresh: (data) => {
    log.debug('onSuccessfulRefresh -> ', data)
    appStorage.setObject('user',{
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      user: {
        _id: data.info.user._id
      }
    });
  },
  onFailedRefresh: (doLogout: boolean, error) => {
    log.debug("Failed Refresh Token, logout: " + doLogout);
    if (doLogout) {
      logout();
    }
    log.debug(error);
  },
});

export { onErrorRefreshTokenLink };
