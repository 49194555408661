// views
import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {  Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { TopNavbar } from "../components/Navbar/TopNavBar";
import { TAppState, TAuthUser } from "../storage";
import { appState } from "../storage/appState";
import { APP_STATE } from "../storage/queries/appState";
import { USER_PRODUCTS } from "../storage/queries/product";
// import { appStorage } from "../storage";
import { USER_LOGGED } from "../storage/queries/userState";
import { Product } from "../storage/types";
import { DashView } from "../views/home/DashView";
import { VegetablesView } from "../views/products/VegetablesView";
import hash from 'object-hash'
import { FruitsView } from "../views/products/FruitsView";
import { DairyView } from "../views/products/DairyView";
import { Test } from "../views/tests/test";
import { ShopView } from "../views/shop/ShopView";
import { InitApp, InitAppRefreshShopFromCloud } from "./InitApp";
import { User, USER_ME } from "../storage/queries/user";
import { LOG } from "../config";
import { UserView } from "../views/home/User";
import { Loading } from "../ui";
interface AdminProps { }

const log = LOG.extend('Layouts')

const Admin = (props: AdminProps) => {
  log.debug('Admin Layout')
  let navigate = useNavigate();
  const { data, loading, error } = useQuery(USER_LOGGED) as unknown as {data: {userLogged: TAuthUser}, loading: boolean, error: string}

  useEffect(() => {
    if (!data?.userLogged) {
      log.debug('User is not logged, redirecting to /auth')
      navigate('/auth')
    }
  },[data])

  if (loading) {
    log.debug('Admin Layout USER_LOGGED loading')
    return <div className="grid place-content-center h-full">
      <Loading />
      <div>
          Caricamento Utente
      </div>
    </div>
    }

  if (error) {
    log.debug(`Admin Layout USER_LOGGED error ${error}`)
    return (<div>{error}</div>)
  }

  return (
    <div className="h-screen">
      <InitAppRefreshShopFromCloud>
          <TopNavbar />
          <div className="pt-16 h-full">
            <Routes>
              <Route path="products/vegetables/*" element={<VegetablesView />} />
              <Route path="products/fruits/*" element={<FruitsView />} />
              <Route path="products/dairy/*" element={<DairyView />} />
              <Route path="shop" element={<ShopView />} />
              <Route path="user" element={<UserView />} />
              <Route path="test" element={<Test />} />
              <Route path="*" element={<DashView />} />
            </Routes>
          </div>
      </InitAppRefreshShopFromCloud>
    </div>
  );
};

export { Admin };
