import { Link } from "react-router-dom"
import { TopNavbar } from "../../components/Navbar/TopNavBar"

interface DashCardProps {
    link: string
    url: string;
    name: string
  }
  
  const DashCardVertical = (props: DashCardProps) => {
    return <div className="min-h-full max-h-full">
                <Link to={props.link} >
                    <div className="block mx-auto h-5/6">
                        <div className="flex justify-center h-full w-full">
                            <img className="h-full w-full object-cover rounded-xl p-2" src={props.url} alt={props.name} />
                        </div>
                    </div>
                    <div className="grid text-lg font-bold uppercase place-items-center w-full">
                        {props.name}
                    </div>
                </Link>
            </div>
  }
  
  const DashCardHorizontal = (props: DashCardProps) => {
    return <div className="h-48">
                <Link to={props.link}>
                    <div className="flex ">
                        <img className="h-48 rounded-xl p-2" src={props.url} alt={props.name} />
                        <div className="grid text-lg font-bold uppercase place-items-center w-full">
                            {props.name}
                        </div>
                    </div>
                </Link>
                </div>  
  }

export const Test = () => {
    return (
        <div className="min-h-screen h-screen">
            <TopNavbar />
            <div className="h-full pt-16">
                <div className="h-28 place-content-center">
                    <div>Sponsor</div>
                </div>
                <div className="h-[calc(100%_-_7rem)]">
                    <div className="h-[calc(100%_-_12rem)] ">
                        <div className="grid grid-cols-2 h-full">
                            <DashCardVertical url="/images/frutta.jpg" link="/products/fruits" name="Frutta"/>
                            <DashCardVertical url="/images/verdura.jpg" link="/products/vegetables" name="Verdura"/>
                        </div>
                    </div>
                    <div className="w-full ">
                        <DashCardHorizontal url="/images/salumi_latticini.jpg" link="/products/dairy" name="Salumi e Formaggi"/>
                    </div>
                </div>
            </div>
        </div>
    )
}