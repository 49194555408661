import { Button, Loading } from "../../ui"
import { logout } from "../../libs/auth";
import { useQuery } from "@apollo/client";
import { USER_LOGGED } from "../../storage/queries/userState";
import { TAuthUser } from "../../storage";
import { User, USER_ME } from "../../storage/queries/user";
import { ProductCartList } from "../../components/Product/ProductCartList";
import { transformProductOrderTypes } from "../../libs/toolbox";
import pack from "../../../package.json";

interface UserViewProps {

}

export const UserView = (props: UserViewProps) => {

    const { data, loading, error } = useQuery(USER_ME,{fetchPolicy: "network-only"}) as unknown as {data: {UserMe: User}, loading: boolean, error: string}

    if (loading) return <div className="grid place-content-center h-full">
        <Loading />
        <div>
            Caricamento Info Utente
        </div>
    </div>
    if (error) return <div className="grid content-center text-center">Errore in caricamento Info utente, verificare connessione internet</div>
    if (!data) return <div>No data</div>

    const infoVarie = <div>
        <div>Info varie: </div>
    </div>

    const asyncLogout = async () => {
        await logout();
        window.location.href = "/";
    }

    return <div className="flex flex-col space-y-4 pt-4">
        <div className="text-center text-3xl">Onubo s.r.l.</div>
        <div className="text-center text-2xl">info@onubo.com</div>
        <div className="flex justify-center">
            <Button size="lg" color="red" disabled={false} onClick={asyncLogout}>Logout</Button>
        </div>
        <div className="flex flex-col">
            <div className="text-center text-2xl">Ultimo ordine</div>
            {
                (data &&
                 data.UserMe &&
                 data.UserMe.lastOrders &&
                 data.UserMe.lastOrders[0]) ?
                    <div className="text-center text-lg">{new Date(data.UserMe.lastOrders[0].createdAt).toLocaleString('it-IT')}</div>:
                    <div>No ordini presenti.</div>
            }
            <div className="grid place-content-center pt-2 uppercase">
                <div className="text-center text-2xl">Note</div>
                <div>
                {
                (data &&
                    data.UserMe &&
                    data.UserMe.lastOrders &&
                    data.UserMe.lastOrders[0] &&
                    data.UserMe.lastOrders[0].info &&
                    data.UserMe.lastOrders[0].info !== ""
                    ) ?
                    data.UserMe.lastOrders[0].info : `Nessuna nota presente.`
                }

                </div>
                <div className="text-center text-2xl">Prodotti</div>
            {
                (data &&
                    data.UserMe &&
                    data.UserMe.lastOrders &&
                    data.UserMe.lastOrders[0]) ?
                    data.UserMe.lastOrders[0].products.map((product, index) => <div key={index}>{product.product.name} - {transformProductOrderTypes(product.product.orderType, product.quantity)}</div>) : `Nessun prodotto nell'ordine`
            }
            </div>
        </div>
        <div className="fixed bottom-2 w-full">
            <div className="text-center text-white  bg-black/75 mx-2 rounded-md py-2">
               Powered by Onubo s.r.l. - versione<Button color="transparent" onClick={()=>window.location.reload()}>{pack.version}</Button> 
            </div>
        </div>
        <div className="h-12"></div> 
    </div>
}