import { logger, configLoggerType, consoleTransport, sentryTransport, transportFunctionType } from 'react-native-logs';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

interface EnvVars {
  mode: 'development' | 'production';
  auth: {
    url: string;
  };
  backend: {
    url: string;
  };
}

const CONFIG: EnvVars = {
  mode: 'production',
  auth: {
    url: 'https://auth.lucin.onubo.com',
  },
  backend: {
    url: 'https://backend.lucin.onubo.com/graphql',
  },
};

Sentry.init({
  dsn: "https://427ae5e3776f4b239da46e2bae0c6cd9@o428532.ingest.sentry.io/6414037",
  integrations: [new BrowserTracing()],
  environment: 'development',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

const loggerConfig: configLoggerType = {
  transport: (props) => {
    if (props.level.severity >= 2) {
      sentryTransport(props);
    } 
    consoleTransport(props);
  } ,
  severity: 'debug',
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  transportOptions: {
    colors: {
      info: 'blue',
      warn: 'yello',
      debug: 'magenta',
      error: 'red',
    },
    SENTRY: Sentry
  },
  async: true,
  dateFormat: 'time',
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: ["Index", "InitApp", "Apollo", 'Views', "Layouts", "Auth", "Unknown", "Reducers"]
};

type myLoggerType = {
  debug: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
  extend: any;
  setSeverity: any;
};

const LOG = logger.createLogger(loggerConfig) as any as myLoggerType;

LOG.debug('Starting new Session')

export { CONFIG, LOG };
