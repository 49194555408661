import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LOG } from "../config";
import { USER_LOGGED } from "../storage/queries/userState";
import { AutoLogin } from "../views/auth/AutoLogin";

import { Login } from "../views/auth/Login";

const log = LOG.extend('Layouts')

interface AuthProps {}

const Auth = (props: AuthProps) => {
  log.debug('Auth Layout')
  let navigate = useNavigate();
  const { data, loading, error } = useQuery(USER_LOGGED);

  const initApp = async () => {
    log.debug('Initializing App in Auth Layout')
    if (data && data.userLogged) {
      navigate('/')
    } else {
      log.debug('User not logged or data not received yet')
    }
  };

  useEffect(() => {
    log.debug('Effect in Auth Layout')
    initApp();
  });
  if (error) return <div>{error}</div>
  if (loading) return <div>{loading}</div>

  return (
    <>
      <section className="">
        <Routes>
          <Route path="newUser/:email/:code" element={<AutoLogin />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </section>
    </>
  );
};

export { Auth };
