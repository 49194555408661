import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { LocalProduct, Product } from "../../storage/types";
import { useQuery } from "@apollo/client";
import { USER_PRODUCTS } from "../../storage/queries/product";
import { useEffect, useState } from "react";
import { translateOrderType } from "../../libs/toolbox";
import { TAppState } from "../../storage";
import { appState } from "../../storage/appState";
import { Textfit } from 'react-textfit';
import { LOG } from "../../config";
import { useDispatch } from "react-redux";
import { increaseProductQuantity, decreaseProductQuantity } from "../../storage/redux/shopSlice";

type InnerCardProps = {
    product: LocalProduct
}

const log = LOG.extend('Views')

export const InnerCard = (props: InnerCardProps) => {
    const dispatch = useDispatch();
    log.debug('InnerCard')
  
    const productQuantityIncrease = () => {
        log.debug('ProductQuantity increase request')
        dispatch(increaseProductQuantity(props.product))
    }

    const productQuantityDecrease = () => {
        log.debug('ProductQuantity decrease request')
        dispatch(decreaseProductQuantity(props.product))
    }
    
    return <div className="flex h-28">
                <div className="p-1 w-48 md:w-96">
                    <div className="relative h-full">
                        {
                            (props.product.ifSponsored) && (<div className="absolute bottom-0 h-8 bg-red-600 w-full grid content-center text-center text-white font-bold">
                                    OFFERTA
                                </div>)
                        }
                        <img className="rounded-lg h-full object-cover w-full" src={props.product.image?.url} alt={props.product.name} />
                    </div>
                </div>
                <div className="flex flex-col rounded-lg m-1 text-center w-full">
                    <Textfit className="flex justify-center items-center h-1/2" mode="single" forceSingleModeWidth={false}>
                        {props.product.name}
                    </Textfit>
                    <div className="grid grid-cols-7">
                        <div className="grid grid-cols-2 col-span-6 text-center">
                            <div>
                                Prezzo
                            </div>
                            <div className={props.product.ifSponsored ? "text-red-600": ""}>
                                {props.product.price} €/{translateOrderType(props.product.priceType)}
                            </div>
                            <div>
                                In ordine
                            </div>
                            <div>
                                {props.product.quantity} {translateOrderType(props.product.orderType)}
                            </div>
                        </div>
                        <div className="grid content-center justify-items-center col-span-1 space-y-2">
                            <button type="button" onClick={productQuantityIncrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                            </button>
                            <button type="button" onClick={productQuantityDecrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                            </button> 
                        </div>
                    </div>
                </div>
            </div>
}

export const InnerCardVertical = (props: InnerCardProps) => {
    const dispatch = useDispatch();
    log.debug('InnerCardVertical')
  
    const productQuantityIncrease = () => {
        log.debug('ProductQuantity increase request')
        dispatch(increaseProductQuantity(props.product))
    }

    const productQuantityDecrease = () => {
        log.debug('ProductQuantity decrease request')
        dispatch(decreaseProductQuantity(props.product))
    }
    
    const image = props.product.ifSponsored ? <div className="grid grid-cols-1 p-2 h-48">
            <img className="rounded-t-lg object-cover h-36" src={props.product.image?.url} alt={props.product.name} />
            <div className="grid grid-cols-1 justify-items-center content-center h-12 bg-red-600 text-white uppercase font-bold">
                Offerta
            </div>
        </div> : <div className="grid grid-cols-1 p-2 h-48">
                    {/* imagine */}
                    <img className="rounded-lg object-cover h-48" src={props.product.image?.url} alt={props.product.name} />
                </div>

    return <div className="flex flex-col border-2 rounded-xl">
                <div className="flex p-1 pt-4 w-full h-20">
                    <Textfit className="w-full text-center" mode="multi" forceSingleModeWidth={false}>
                        {props.product.name}
                    </Textfit>
                </div>
                {image}
                <div className="p-2 h-20 grid grid-cols-7 content-between">
                    <div className="col-span-6 grid grid-cols-2 h-20 content-center gap-y-2">
                        <div className="">
                            Prezzo
                        </div>
                        <div className={`flex text-center ${props.product.ifSponsored? 'text-red-600': ''}`}>
                            {props.product.price} <div className="pl-1 italic">€/{translateOrderType(props.product.priceType)}</div>
                        </div>
                        <div className="">
                            In ordine
                        </div>
                        <div className="text-center">
                            {props.product.quantity} {translateOrderType(props.product.orderType)}
                        </div>
                    </div>
                    <div className="grid justify-items-center content-center row-span-3">
                        <div className="grid grid-cols-1 ">
                            <button type="button" onClick={productQuantityIncrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                            </button>
                            <button onClick={productQuantityDecrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                            </button>
                        </div>
                    </div>
                    {/* <div className="flex flex-row h-1/2 items-center">
                        <div className="flex flex-col w-3/5 text-2xl">
                            <div className="flex justify-center">{props.product.price}<div className="pl-1 italic">€/{translateOrderType(props.product.priceType)}</div></div>
                        </div>
                        <div className="flex flex-col w-1/5 text-2xl">
                            <div>{props.product.quantity}</div>
                            <div className="italic">{translateOrderType(props.product.orderType)}</div>
                        </div>
                        <div className="flex flex-col w-1/5 items-center">
                            <div onClick={productQuantityIncrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" /></svg>
                            </div>
                            <div onClick={productQuantityDecrease}>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" /></svg>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
}

type CardSliderProps = {
    products: LocalProduct[]
}

export const CardSlider = (props: CardSliderProps) => {
    log.debug('CardSlider')
    log.debug('Mapping products for carousel')
    const CarouselCards = props.products.map((product, index) => <div key={index}><InnerCard product={product}/></div>)

    return <div className="shadow-2xl rounded-lg">
        <Carousel 
            key='carusel'
            showThumbs={false} 
            className=""
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop={true}
            showArrows={false}
            >
            {CarouselCards}
        </Carousel>
    </div>
}