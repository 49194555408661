import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShoppingCart } from '../User/ShoppingCart';
import { UserMenu } from '../User/UserMenu';
import { PrintRoute } from './printRoute';



export const TopNavbar = () => {
  const pathName = useLocation().pathname;
  let background = null
  switch (pathName) {
    case '/':
      break;
    case '/products/fruits':
      background = <img className="absolute top-0 left-0 h-full w-full object-cover opacity-30" src="/images/frutta.jpg" alt="Salumi, Formaggi e uova" />
      break;
    case '/products/vegetables':
      background = <img className="absolute top-0 left-0 h-full w-full object-cover opacity-40" src="/images/verdura.jpg" alt="Verdura" />
      break;
    case '/products/dairy':
      background = <img className="absolute top-0 left-0 h-full w-full object-cover opacity-50" src="/images/salumi_latticini.jpg" alt="Salumi, Formaggi e uova" />
      break;
  }
  return (
    <>
      <nav
        style={{ zIndex: 999 }}
        className="fixed top-0 left-0 w-full z-10 bg-white flex flex-row py-4 px-2 justify-between h-16 items-center"
      >
        <div className='w-full z-10'>
          <PrintRoute />
        </div>
        <div className='flex w-48 justify-around z-10'>
            <UserMenu />
            <ShoppingCart />
        </div>
        {background}
      </nav>
    </>
  );
};

