import { Link, useLocation } from "react-router-dom";

export const BottonNavbar = () => {
  const pathName = useLocation().pathname;

  const pages = [
    {
      path: "/",
      title: "Lucin Food & Flower",
      name: "Home",
      image: "/images/farm.png",
    },
    {
      path: "/products/fruits",
      name: "Frutta",
      image: "/images/frutta.jpg",
    },
    {
      path: "/products/vegetables",
      name: "Verdura",
      image: "/images/verdura.jpg",
    },
    {
      path: "/products/dairy",
      name: "Salumi e formaggi",
      image: "/images/salumi_latticini.jpg",
    },
  ]

  const Image = (props: {image: string, title?: string, name: string, isActive: boolean}) => {
    if (!props.title) {
      return <div className="h-full sm:h-2/3">
        {(props.isActive)?
          <img className="rounded-t-2xl h-full w-full object-cover" src={props.image} alt={props.name} /> : 
          <img className="hover:animate-pulse brightness-50 rounded-t-2xl h-full w-full object-cover" src={props.image} alt={props.name} />
        }
      </div>
    } else {
      return <div className="flex justify-center h-full sm:h-2/3 w-full">
          {(props.isActive)?
            <img className="rounded-t-2xl h-12 w-12 sm:h-16 sm:w-16  object-cover" src={props.image} alt={props.name} /> : 
            <img className="hover:animate-pulse brightness-50 rounded-t-2xl h-12 w-12 sm:h-16 sm:w-16 object-cover" src={props.image} alt={props.name} />
          }
          <div className="hidden sm:grid hover:animate-pulse text-center content-center h-full text-2xl w-full">
            {props.title}
          </div>
      </div>
    }
  }

  const NavButton = (props: {name: string, path: string, image: string, activePath: string, title?: string}) => {
    return <Link to={props.path} className="flex flex-col h-full w-full">
      <Image {...props} isActive={(props.activePath === props.path)} />
      {/* <div className="h-full sm:h-2/3">
      {(props.activePath === props.path)?
        <img className="rounded-t-2xl h-full w-full object-cover" src={props.image} alt={props.name} /> : 
        <img className="hover:animate-pulse brightness-50 rounded-t-2xl h-full w-full object-cover" src={props.image} alt={props.name} />
      }
      </div> */}
      <div className="hidden sm:h-1/3 sm:grid content-center h-1/3 text-center font-bold uppercase lg:text-xl xl:text-3xl">
        {props.name}
      </div>
    </Link>
  }

  const buttons = pages.map((page) => {
    return <NavButton {...page} activePath={pathName} />
  })


  return (
      <nav
        style={{ zIndex: 999 }}
        className="fixed bottom-0 w-screen z-10 bg-white flex flex-row space-x-4 justify-around h-12 sm:h-32 items-center pt-2 px-4"
      >
        {buttons}
      </nav>
  );
};

