import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CardSlider } from "../../components/Product/CardSlider";
import { selectShop } from "../../storage/redux/shopSlice";
import { Loading } from "../../ui";
import { BottonNavbar } from "../../components/Navbar/BottonNavBar";

interface DashCardProps {
  link: string
  url: string;
  name: string
  nameSize?: "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl"
}

const DashCardVertical = (props: DashCardProps) => {
  return <div className="h-full">
              <Link to={props.link} className="h-full">
                  <div className="h-full">
                      <div className="flex justify-center h-full">
                          <img className="object-cover h-full" src={props.url} alt={props.name} />
                      </div>
                  </div>
                  <div className={`grid text-${props.nameSize || 'lg'} uppercase place-items-center w-full`}>
                      {props.name}
                  </div>
              </Link>
          </div>
}

const DashCardHorizontal = (props: DashCardProps) => {
  return <div className="h-48 hover:animate-pulse">
              <Link to={props.link}>
                  <div className="flex">
                    <img className="object-cover h-48 w-96 rounded-l-3xl" src={props.url} alt={props.name} />
                    <div className={`grid text-${props.nameSize || 'lg'} font-bold uppercase place-items-center w-full text-center bg-gray-200`}>
                        {props.name}
                    </div>
                  </div>
              </Link>
              </div>  
}

// const DashCardHorizontal = (props: DashCardProps) => {
//   return <div className="h-48 w-full relative">
//             <img className="absolute object-cover h-48 w-96 rounded-3xl p-2" src={props.url} alt={props.name} />
//             <div className="absolute text-4xl uppercase w-full text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
//                 {props.name}
//             </div>
//           </div>  
// }



interface DashViewProps {
  route?: any;
}


const DashView = (props: DashViewProps) => {
  const shop = useSelector(selectShop);
  // const { data, loading, error } = useQuery<{appState: {products: LocalProduct[]}}>(APP_STATE)
  if (!shop) return <div className="grid place-content-center h-full">
    <Loading />
    <div>
        Caricamento shop
    </div>
  </div>

  // if (error) return <div>Error...</div>
  const sponsoredProducts = shop.products.filter((product) => product.ifSponsored)

  const Offerte = () => {
    if (sponsoredProducts.length === 0) return null
    return <div className="h-48 px-4 w-full pb-2">
      <div className="grid items-center text-center h-12 bg-red-600 rounded-t-xl lg:text-3xl text-xl">
        OFFERTE DELLA SETTIMANA
      </div>
      <div className="h-36">
        <CardSlider products={sponsoredProducts}/>
      </div>
    </div>
  }

  return (
      <>
        <div className='sm:hidden h-full flex flex-col'>
          {
          sponsoredProducts.length > 0 ? <div className="pt-2 h-36">
            <div className="w-full text-center bg-red-600 text-white">
              OFFERTE DELLA SETTIMANA
            </div>
            <CardSlider products={sponsoredProducts}/>
          </div> : null 
          }
          <div className="flex flex-col h-full">
            <div className="h-48">
              <Link to="/products/dairy" className="h-full">
                <div className="flex w-full h-full p-2">
                  <div className="relative w-1/3 h-full">
                    <img className="absolute h-full w-full object-cover border-2 rounded-xl" src="/images/salumi_latticini.jpg" alt="Salumi, Formaggi e uova" />
                  </div>
                  <div className="grid content-center text-center w-2/3 h-full text-2xl uppercase font-thin">
                    Salumi, Formaggi e uova
                  </div>
                </div>
              </Link>
            </div>
            <div className="flex h-full w-full p-2 gap-1">
              <div className="relative flex flex-col h-full w-full ">
                <Link to="/products/fruits" className="h-full">
                  <img className="absolute h-full w-full object-cover border-2 rounded-xl" src="/images/frutta.jpg" alt="Frutta" />
                  <div className="bg-white absolute bottom-0 w-full text-2xl text-center font-thin">FRUTTA</div>
                </Link>
              </div>
              <div className="relative flex h-full w-full ">
                <Link to="/products/vegetables" className="h-full">
                  <img className="absolute h-full w-full object-cover border-2 rounded-xl" src="/images/verdura.jpg" alt="Verdura" />
                  <div className="bg-white absolute bottom-0 w-full text-2xl text-center font-thin">VERDURA</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden sm:grid grid-cols-2 h-full justify-items-center'>
          <div className="grid grid-cols-1 justify-items-center">
            <img className="relative h-full w-full object-scale-down max-w-xl p-8" src="/images/LOGO-LUCIN-andalo.png" alt="Lucin"></img>
            <Offerte />
          </div>
          <div className="grid content-around space-y-4 pb-8">
            {/* <div>
              {
                sponsoredProducts.length > 0 ? <div className="h-32 p-2">
                  <CardSlider products={sponsoredProducts}/>
                </div> : null 
              }
            </div> */}
            <DashCardHorizontal url="/images/frutta.jpg" link="/products/fruits" name="Frutta" nameSize="4xl"/>
            <DashCardHorizontal url="/images/verdura.jpg" link="/products/vegetables" name="Verdura" nameSize="4xl"/>
            <DashCardHorizontal url="/images/salumi_latticini.jpg" link="/products/dairy" name="Salumi, Formaggi e uova" nameSize="4xl"/>
          </div>
        </div>
      </>
  );
};

export { DashView };
