import { useSelector } from "react-redux";
import { BottonNavbar } from "../../components/Navbar/BottonNavBar";
import { ProductList, ProductListDesktop } from "../../components/Product/ProductList";
import { LOG } from "../../config";
import { selectShop } from "../../storage/redux/shopSlice";
import { ProductTypes } from "../../storage/types";

const log = LOG.extend('Views')

const convertProductType = (type: ProductTypes) => {
  switch (type) {
    case ProductTypes.dairyProducts:
      return 'Salumi e Formaggi'
    case ProductTypes.fruits:
      return 'Frutta'
    case ProductTypes.vegetables:
      return 'Verdura'
    default:
      return type
  }
}

interface ProductsViewProps {
    route?: any;
  }
  
export const FruitsView = (props: ProductsViewProps) => {
  log.debug('FruitsView')
  const shop = useSelector(selectShop);
  if (!shop) {
    log.debug(`shop state not ready`)
    return <div>loading state...</div>
  }
  log.debug('Filtering fruits, check if image and visible')
  const fruits = shop.products.filter((product) => (product.type === ProductTypes.fruits && product.image?.url && product.ifVisible))
  log.debug('Filtering sponsored products')
  const sponsoredProducts = shop.products.filter((product) => product.ifSponsored)

  return (
    <div className=""> 
      <div className="sm:hidden">
        <ProductList name='fruits' products={fruits} sponsoredProducts={sponsoredProducts}/>
      </div>
      <div className="hidden sm:grid">
        <ProductListDesktop name='fruits' products={fruits} sponsoredProducts={sponsoredProducts}/>
      </div>
        <BottonNavbar />
    </div>
  );
};
  