import { Link, useLocation } from "react-router-dom"

const routes = [{
        path: '/', 
        name: 'home',  
        title: 'Lucin Food & Flower',
        subTitle: '0461 58 52 92'
    },
    {
        path: '/products/fruits', 
        name: 'fruits',  
        title: 'Frutta',
        subTitle: '0461 58 52 92'
    },
    {
        path: '/products/vegetables', 
        name: 'vegetables',  
        title: 'Verdura',
        subTitle: '0461 58 52 92'
    },
    {
        path: '/products/dairy', 
        name: 'dairyProducts',  
        title: 'Salumi e formaggi',
        subTitle: '0461 58 52 92'
    },
    {
        path: '/shop', 
        name: 'shop',  
        title: 'Carrello',
        subTitle: '0461 58 52 92'
    },
  ];

export const PrintRoute = () => {
    const pathName = useLocation().pathname;
    const route = routes.find((route) => route.path === pathName)
    return <div className="text-center font-bold">
        <div className="text-2xl">{(route)? route.title : routes[0].title}</div>
        <div className="text-xs">{(route)? route.subTitle : routes[0].subTitle}</div>
    </div>
}
