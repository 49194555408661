export enum ProductTypes {
    vegetables = "vegetables",
    fruits = "fruits",
    dairyProducts = "dairyProducts"
}

export enum ProductOrderTypes {
    kg = "kg",
    piece = "piece",
    box = "box",
    vs = "vs"
}

export class Image {
    name?: string;
    url?: string;
}

export class Product {
    "_id": string;
    "name": string;
    "price": number;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "orderType": ProductOrderTypes
    "priceType": ProductOrderTypes
    "externalId"?: string;
    "deliveryTime"?: number;
    "image"?: Image;
}

export class LocalProduct extends Product {
    "quantity": number;
  }
  
  export class OrderCreateInput {
    "products" : {
        "product": string,
        "quantity": number
    }[]
    "info"?: string
}

export class Order {
    "_id": string;
    "user" : {
        "_id": string
    }
    "createdAt": Date;
    "products" : {
        "product": {
            "_id": string
        }
        "quantity": number
    }[]
}