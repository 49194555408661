import { ProductOrderTypes } from "../storage/types"

export const translateOrderType = (orderType: string) => {
    switch (orderType) {
        case 'kg':
            return 'kg'
        case 'piece':
            return 'pz.'
        case 'box':
            return 'box'
        case 'vs':
            return 'vs'
        default:
            return 'unknown'
    }
}

export const transformProductOrderTypes = (productType: ProductOrderTypes, quantity: number) => {
    switch (productType) {
        case ProductOrderTypes.kg:
            return `${quantity} kg`
        case ProductOrderTypes.piece:
            return quantity
        case ProductOrderTypes.box:
            return (quantity === 1)? `1 cassetta`: `${quantity} cassette`
        case ProductOrderTypes.vs:
            return (quantity === 1)? `1 vaschetta`: `${quantity} vaschette`
        default:
            return quantity
    }
}
