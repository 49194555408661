import { setContext } from "@apollo/client/link/context";
import { appStorage, TAuthUser } from "../../storage";


// Middleware che aggiunge gli header auth alle richieste apollo
const authLink = setContext((_, { headers }) => {
  const user : TAuthUser= appStorage.getObject('user')
  const token = user.accessToken;
  return {
    headers: {
      ...headers,
      authentication: token || "",
    },
  };
});

export { authLink };
