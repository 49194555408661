import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LOG } from '../../config'
import { LocalProduct, Product } from '../types'
import type { RootState } from './store'
import hash from 'object-hash'
import { appStorage } from '..'

const log = LOG.extend('Reducers')

// Define a type for the slice state
interface ShopState {
  products: LocalProduct[],
  dbVersion?: string,
  lastUpdate?: number,
}

// Define the initial state using that type
const initialState: ShopState = {
  products: [],
}

export const shopSlice = createSlice({
  name: 'shop',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initProductsFromCache: (state, action: PayloadAction<{products: LocalProduct[], dbVersion: string, isOnline:boolean}>) => {
      log.debug('Get Products from Cache')
      state.products = action.payload.products
      state.dbVersion = action.payload.dbVersion
      state.lastUpdate = new Date().getTime()
      if (action.payload.isOnline) {
        appStorage.setObject('shop',{
          ...state
        });
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    initProducts: (state, action: PayloadAction<{products: LocalProduct[], dbVersion: string, isOnline:boolean}>) => {
      log.debug('initProducts')
      state.products = action.payload.products
      state.dbVersion = action.payload.dbVersion
      state.lastUpdate = new Date().getTime()
      if (action.payload.isOnline) {
        appStorage.setObject('shop',{
          ...state
        });
      }
    },
    resetProductsQuantity: (state) => {
      state.products = state.products.map((product) => {
        product.quantity = 0
        return product
      })
      appStorage.setObject('shop',{
        ...state
      });
    },
    increaseProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Increasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in shop`)
      } else {
        log.debug(`Product found in the shop, i will increment the quantity`)
        state.products[productIndex].quantity++
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
    decreaseProductQuantity: (state, action: PayloadAction<Product>) => {
      log.debug(`Decreasing product ${action.payload.name} quantity`)
      log.debug(`Finding the product`)
      const productIndex = state.products.findIndex((product) => product._id.toString() === action.payload._id.toString())
      if (productIndex === -1) {
        log.error(`Product NOT present in the shop`)
      } else {
        log.debug(`Product found in the shop, i will decrease the quantity`)
        if (state.products[productIndex].quantity > 0) {
          state.products[productIndex].quantity--
        } else {
          state.products[productIndex].quantity = 0
        }
      }
      appStorage.setObject('shop',{
        ...state
      });
    },
  },
})

export const { increaseProductQuantity, decreaseProductQuantity, initProducts, resetProductsQuantity, initProductsFromCache } = shopSlice.actions

export const selectShop = (state: RootState) => state.shop

export const shopReducer = shopSlice.reducer