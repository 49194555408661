import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { LOG } from "../../config"
import { selectShop } from "../../storage/redux/shopSlice"
import { LocalProduct } from "../../storage/types"
import { TextBox } from "../../ui"
import { CardSlider, InnerCard, InnerCardVertical } from "./CardSlider"

const log = LOG.extend('Views')

type ProductListProps = {
    products: LocalProduct[]
}

export const ProductCartList = (props: ProductListProps) => {
    log.debug('ProductCartList')
    // const shop = useSelector(selectShop);

    log.debug(`Mapping all the products cards`)
    const Cards = props.products.filter((product)=> product.quantity>0).map((product, index) => <div key={index} className="bg-white h-28 mx-2 my-2 shadow-2xl rounded-lg space-y-2"><InnerCard product={product}/></div>)
    
    const noProducts = <img className="absolute h-48 w-full object-scale-down" src="/images/emptyShopCartBig.png" alt="Carrello" />

    return <div id='shoppingCart' className="flex flex-col pb-24">
        <div className={''}>
            {(Cards.length > 0)? Cards : noProducts}
        </div>
        
    </div>
}

export const ProductCartListDesktop = (props: ProductListProps) => {
    log.debug('ProductCartListDesktop')
    // const shop = useSelector(selectShop);

    log.debug(`Mapping all the products cards`)
    const Cards = props.products.filter((product)=> product.quantity>0).map((product, index) => <div key={index} className=""><InnerCardVertical product={product}/></div>)

    return (Cards.length > 0)? <div id='shoppingCart' className="sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2 pt-4">
                {Cards} </div> : <div className="relative h-96 w-full">
                    <img className="absolute h-full w-full object-scale-down" src="/images/emptyShopCartBig.png" alt="Carrello" />
                </div>
}