import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductList } from "../../components/Product/ProductList";
import { LocalProduct, OrderCreateInput, } from "../../storage/types";
import { Button, Loading } from "../../ui";
import { TextArea } from "../../ui/TextArea";
import { createOrderData, createOrderVariables, CREATE_ORDER } from "../../storage/queries/order";
import { LOG } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { resetProductsQuantity, selectShop } from "../../storage/redux/shopSlice";
import { ProductCartList, ProductCartListDesktop } from "../../components/Product/ProductCartList";

const log = LOG.extend('Unknown')

interface ProductsViewProps {
    route?: any;
  }
 
const prepareOrder = (products: LocalProduct[], info?: string) : OrderCreateInput => {
  return {
    info,
    products: products.map((product) => { return {product: product._id, quantity: product.quantity}})
  }
}

export const ShopView = (props: ProductsViewProps) => {
  log.debug('ShopView')
  const shop = useSelector(selectShop);
  const dispatch = useDispatch()

  const [orderError, setOrderError] = useState('')
  const [ifLoading,setIfLoading] = useState(false)
  const [ifOrderSent, setIfOrderSent] = useState(false)
  const [info, setInfo] = useState('')

  const delay = (ms : number) => new Promise(res => setTimeout(res, ms));

  const [createOrder, { error: createOrderError, loading: createOrderLoading }] = useMutation<
    createOrderData,
    createOrderVariables
  >(CREATE_ORDER);

  useEffect(() => {
    log.debug('ShopView effect')
    if (ifLoading !== createOrderLoading) {
      setIfLoading(createOrderLoading)
    }
  },[createOrderLoading])

  if (!shop) {
    log.debug('shop state loading')
    return <div className="grid place-content-center h-full">
      <Loading />
      <div>
          Caricamento shop
      </div>
    </div>
  }
  
  const products = shop.products.filter((product) => (product.quantity > 0))
  
  if (createOrderError) {
    log.debug('Riscontrato errore in createOrder', createOrderError)
    // setOrderError('Qualcosa è andato storto, si prega di riprovare.')
  }

  const sendOrder = async () => {
    log.debug('Sending order')
    const order = prepareOrder(products, info)
    setIfLoading(true)
    await delay(3000)
    const res = await createOrder({
      variables: {
        order,
      },
    });
    if (res?.data?.UserOrderCreate?._id) {
      log.debug('Ordine inserito con successo')
      setOrderError('')
      setInfo('')
      dispatch(resetProductsQuantity())
      setIfOrderSent(true)
  } else {
      log.debug(`qualcosa e' andato storto`)
      setOrderError('Qualcosa è andato storto, si prega di riprovare.')
    }

  }

  if (ifOrderSent) return (
    <div className="flex flex-col justify-center">
        <div className="text-center text-2xl mt-24 mb-8">
          Ordine inviato con successo
        </div>
        <div className="flex justify-center">
          <img src="/images/tractorWithVegetables.jpg" alt="Order Completed" />
        </div>
        <div className="text-center text-2xl mt-8">
          Grazie
        </div>
        <div className="flex fixed bottom-8 w-full space-x-2 px-2">
        {/* <div className="flex space-x-2 h-2/5 w-full"> */}
              <Link to={'/'} className="bg-blue-500 text-center mt-1 rounded-xl content-center w-full">
                <div className="">Torna a Home</div>
              </Link>
              <Link to={'/user'} className="bg-gialloLucin text-center mt-1 rounded-xl content-center  w-full">
                <div className="">Rivedi ultimo ordine</div>
              </Link>
            </div>
        {/* </div> */}

    </div>
  )

  return (
    <>
      {(orderError || createOrderError) ? <div className="flex bg-red-500 rounded-lg mt-2 text-center px-4 w-full my-2">
          Qualcosa e' andato storto!
      </div> : null}
      {(ifLoading)? 
        <div className="flex flex-col">
          <div className="text-center text-2xl mt-24 mb-8">
            Invio Ordine in corso
          </div>
          <div className="flex justify-center">
            <img className="rounded-xl h-48" src="/images/animatedTractor.gif" alt="Loading" />
          </div>
          <div className="text-center text-2xl mt-8">
            Attendere..
          </div>
        </div> : 
        <div className="h-full">
          <div className="sm:hidden">
            <TextArea rows={2} value={info} onChange={(event) => {setInfo(event.target.value)}} className={(orderError!=="")? "mt-16" : "mt-2"} id="note" required={false} type="text" placeholder="Inserisci qui eventuali note da comunicare con l'ordine"/>
            <ProductCartList products={shop.products}/>
            <nav
                style={{ zIndex: 999 }}
                className="fixed bottom-4 left-0 w-full z-10 flex flex-col px-2 h-20"
            >
              {(products.length>0)?
                <Button size="lg" full color="blue" onClick={sendOrder}>Invio Ordine</Button>: null
              }
              <div className="flex space-x-2 h-2/5 w-full">
                <Link to={'/'} className="bg-blue-500 text-center mt-1 rounded-xl content-center w-full">
                  <div className="">Torna a Home</div>
                </Link>
                <Link to={'/user'} className="bg-gialloLucin text-center mt-1 rounded-xl content-center  w-full">
                  <div className="">Vedi ultimo ordine</div>
                </Link>
              </div>
            </nav>
          </div>
          <div className="hidden sm:grid p-2 max-w-screen-xl justify-items-center m-auto pb-32">
            <TextArea rows={4} value={info} onChange={(event) => {setInfo(event.target.value)}} className={(orderError!=="")? "mt-16" : "mt-2"} id="note" required={false} type="text" placeholder="Inserisci qui eventuali note da comunicare con l'ordine"/>
            <ProductCartListDesktop products={shop.products}/>
            <div
                className="fixed bottom-4 left-0 w-full z-10 flex flex-col px-2 h-20"
            >
              {(products.length>0)?
                <Button size="lg" full color="blue" onClick={sendOrder}>Invio Ordine</Button>: null
              }
              <div className="flex space-x-2 h-2/5 w-full">
                <Link to={'/'} className="bg-blue-500 text-center mt-1 rounded-xl content-center w-full">
                  <div className="">Torna a Home</div>
                </Link>
                <Link to={'/user'} className="bg-gialloLucin text-center mt-1 rounded-xl content-center  w-full">
                  <div className="">Vedi ultimo ordine</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
  