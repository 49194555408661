import { useSelector } from "react-redux";
import { BottonNavbar } from "../../components/Navbar/BottonNavBar";
import { ProductList, ProductListDesktop } from "../../components/Product/ProductList";
import { LOG } from "../../config";
import { selectShop } from "../../storage/redux/shopSlice";
import { ProductTypes } from "../../storage/types";

const log = LOG.extend('Views')

interface ProductsViewProps {
    route?: any;
  }
  
export const VegetablesView = (props: ProductsViewProps) => {
  log.debug('FruitsView')
  const shop = useSelector(selectShop);

  if (!shop) {
    log.debug(`shop state not ready`)
    return <div>loading state...</div>
  }

  log.debug('Filtering vegetables, check if image and visible')
  const vegetables = shop.products.filter((product) => (product.type === ProductTypes.vegetables && product.image?.url && product.ifVisible))
  log.debug('Filtering sponsored products')
  const sponsoredProducts = shop.products.filter((product) => product.ifSponsored)

  return (
    <>
      <div className="sm:hidden">
        <ProductList name='vegetables' products={vegetables} sponsoredProducts={sponsoredProducts}/>
      </div>
      <div className="hidden sm:grid">
        <ProductListDesktop name='vegetables' products={vegetables} sponsoredProducts={sponsoredProducts}/>
      </div>
        <BottonNavbar />
    </>

  );
};
  