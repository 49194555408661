import { gql } from '@apollo/client';
import { LocalProduct, Order, Product } from '../types';

interface User {
  _id: string;
  email: string;
  basicInfo: {
    name: string;
  };
  auth: {
    createdAt: string;
    createdWith: string;
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
  };
  lastOrders: {
    _id: string;
    createdAt: string;
    info: String,
    products: {
      product: LocalProduct,
      quantity: number
    }[];
  }[]
}

const USER_ME = gql`
  query USER_ME {
    UserMe {
      _id
      email
      basicInfo {
        name
      }
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        permission {
          scopes
        }
        lastLogin
      }
      lastOrders {
        _id
        createdAt
        info
        products {
          product {
            _id
            name
            price
            type
            orderType
            priceType
            deliveryTime
          }
          quantity
        }
      }
    }
  }
`;

export { USER_ME };
export type { User };
